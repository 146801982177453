export default [{
    "type": "eFoil",
    "name": "eFoil",
    "img": "../assets/img/types/flite_air.webp",
    "description_small": 'eFoil - серф с электрическим двигателем и подводным крылом.',
    "description": 'eFoil - серф с электрическим двигателем и подводным крылом. eFoil отрывается от поверхности воды и катание превращается в полёт! Управляешь доской с помощью тела, а её скоростью (до 50км/ч) с помощью пульта в руке. И да, чтобы посёрфить не нужно ждать волны, грести до неё - можно сразу учиться и кайфовать.',
    "points": [
        {
            id: '1eadacdf-3d1c-4785-987b-392066d9be2b',
            name: 'Морозово',
            img: '../assets/img/points/morozovo.webp',
            types: {
                efoil: 2,
                ejet: 5,
                'sup-board': 2,
            },
            description_small: 'Обучение и прокат электросёрфов и SUP-бордов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями. Услуги проката и инструктора доступны без проживания в отеле.<br>' +
                'Тарифы:<br>' +
                'SUP-борд - 1 час - 800₽<br>' +
                'Электросёрф - 20 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 3 500₽<br>' +
                'Электросёрф - 60 минут - 6 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>' +
                'Специальные тарифы на проживание в отеле и сауну.',
            description_full: 'Обучение и прокат электросёрфов и SUP-бордов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями. Услуги проката и инструктора доступны без проживания в отеле.<br>' +
                'Тарифы:<br>' +
                'SUP-борд - 1 час - 800₽<br>' +
                'Электросёрф - 20 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 3 500₽<br>' +
                'Электросёрф - 60 минут - 6 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>' +
                'Специальные тарифы на проживание в отеле и сауну.',
            enabled: true,
            open_chat:true
        },
        {
            id: '1eadacdf-3d1c-4785-987b-392066d9be2d',
            name: 'Адлер',
            img: '../assets/img/points/adler.webp',
            types: {
                efoil: 2,
                ejet: 5,
                'sup-board': 2,
            },
            description_small: 'Обучение и прокат электросёрфов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями.<br>' +
                'Тарифы:<br>' +
                'Электросёрф - 10 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 5 000₽<br>' +
                'Электросёрф - 60 минут - 9 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>',
            description_full: 'Обучение и прокат электросёрфов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями.<br>' +
                'Тарифы:<br>' +
                'Электросёрф - 10 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 5 000₽<br>' +
                'Электросёрф - 60 минут - 9 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>',
            enabled: true,
            open_chat:true
        }
    ]
},{
    "type": "eJet",
    "name": "eJet",
    "img": "../assets/img/types/radinn_carve.webp",
    "description_small": 'eJet - электросерф с водомётным двигателем.',
    "description": 'eJet - электросерф с водомётным двигателем. eJet скользит по поверхности воды на скорости до 50 км/ч, которую ты сам контролируешь - пульт в руке! Не нужно ждать волну и грести до неё, остаётся только приноровиться и поймать баланс.',
    "points": [
        {
            id: '1eadacdf-3d1c-4785-987b-392066d9be2b',
            name: 'Морозово',
            img: '../assets/img/points/morozovo.webp',
            types: {
                efoil: 2,
                ejet: 5,
                'sup-board': 2,
            },
            description_small: 'Обучение и прокат электросёрфов и SUP-бордов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями. Услуги проката и инструктора доступны без проживания в отеле.<br>' +
                'Тарифы:<br>' +
                'SUP-борд - 1 час - 800₽<br>' +
                'Электросёрф - 20 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 3 500₽<br>' +
                'Электросёрф - 60 минут - 6 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>' +
                'Специальные тарифы на проживание в отеле и сауну.',
            description_full: 'Обучение и прокат электросёрфов и SUP-бордов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями. Услуги проката и инструктора доступны без проживания в отеле.<br>' +
                'Тарифы:<br>' +
                'SUP-борд - 1 час - 800₽<br>' +
                'Электросёрф - 20 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 3 500₽<br>' +
                'Электросёрф - 60 минут - 6 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>' +
                'Специальные тарифы на проживание в отеле и сауну.',
            enabled: true,
            open_chat:true
        },
        {
            id: '1eadacdf-3d1c-4785-987b-392066d9be2d',
            name: 'Адлер',
            img: '../assets/img/points/adler.webp',
            types: {
                efoil: 2,
                ejet: 5,
                'sup-board': 2,
            },
            description_small: 'Обучение и прокат электросёрфов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями.<br>' +
                'Тарифы:<br>' +
                'Электросёрф - 10 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 5 000₽<br>' +
                'Электросёрф - 60 минут - 9 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>',
            description_full: 'Обучение и прокат электросёрфов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями.<br>' +
                'Тарифы:<br>' +
                'Электросёрф - 10 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 5 000₽<br>' +
                'Электросёрф - 60 минут - 9 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>',
            enabled: true,
            open_chat:true
        }
    ]
},{
    "type": "sup-board",
    "name": "SUP-Board",
    "img": "../assets/img/types/sup_yellow.webp",
    "description_small": 'SUP – Stand Up Paddle, означает «Стоять с веслом».',
    "description": 'SUP – Stand Up Paddle, означает «Стоять с веслом». Кататься на САПе просто: человек стоит на доске и скользит по едет по воде, гребя длинным веслом.',
    "points": [
        {
            id: '1eadacdf-3d1c-4785-987b-392066d9be2b',
            name: 'Морозово',
            img: '../assets/img/points/morozovo.webp',
            types: {
                efoil: 2,
                ejet: 5,
                'sup-board': 2,
            },
            description_small: 'Обучение и прокат электросёрфов и SUP-бордов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями. Услуги проката и инструктора доступны без проживания в отеле.<br>' +
                'Тарифы:<br>' +
                'SUP-борд - 1 час - 800₽<br>' +
                'Электросёрф - 20 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 3 500₽<br>' +
                'Электросёрф - 60 минут - 6 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>' +
                'Специальные тарифы на проживание в отеле и сауну.',
            description_full: 'Обучение и прокат электросёрфов и SUP-бордов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями. Услуги проката и инструктора доступны без проживания в отеле.<br>' +
                'Тарифы:<br>' +
                'SUP-борд - 1 час - 800₽<br>' +
                'Электросёрф - 20 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 3 500₽<br>' +
                'Электросёрф - 60 минут - 6 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>' +
                'Специальные тарифы на проживание в отеле и сауну.',
            enabled: true,
            open_chat:true
        },
        {
            id: '1eadacdf-3d1c-4785-987b-392066d9be2d',
            name: 'Адлер',
            img: '../assets/img/points/adler.webp',
            types: {
                efoil: 2,
                ejet: 5,
                'sup-board': 2,
            },
            description_small: 'Обучение и прокат электросёрфов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями.<br>' +
                'Тарифы:<br>' +
                'Электросёрф - 10 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 5 000₽<br>' +
                'Электросёрф - 60 минут - 9 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>',
            description_full: 'Обучение и прокат электросёрфов. Предоставим всю необходимую экипировку. Услуги профессионального инструктора включены в стоимость, научим за одно занятие. Оборудование ведущих мировых производителей Fliteboard (Австралия) и Radinn (Швеция).<br>' +
                'Снимем фото и видео для социальных сетей, чтобы Вы поделились своим восторгом с друзьями.<br>' +
                'Тарифы:<br>' +
                'Электросёрф - 10 минут - 2 500₽<br>' +
                'Электросёрф - 30 минут - 5 000₽<br>' +
                'Электросёрф - 60 минут - 9 000₽<br>' +
                'Абонементы на 3, 5 и 10 часов по сниженным ценам.<br>',
            enabled: true,
            open_chat:true
        }
    ]
},
]
